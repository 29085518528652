.h2 {
  font-weight: lighter;
}

.text {
  height: min-content;
}

.card:hover {
  background-color: lightgray;
  transition-duration: 0.2s;
}

.card:not(:hover) {
  background: white;
  transition-duration: 0.2s;
}
